import { useEffect, useState } from 'react'
import './App.css'
import './ViewFlows'
import ViewFlows from './ViewFlows'
import HomePage from './HomePage'
import type { accountType } from '../../types'

function App() {
    const [message, setMessage] = useState([] as accountType[])
    const [selectedInstance, setInstance] = useState<accountType | null>()

    useEffect(() => {
        fetch('/list')
            .then((response) => response.text())
            .then((data) => setMessage(JSON.parse(data)))
    }, [])

    var accounts
    if (!message) accounts = [] as accountType[]
    else accounts = message

    return (
        <div className="App">
            <header className="App-header">
                <a onClick={() => setInstance(undefined)}>
                    <div className="home-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="100"
                            height="100"
                            viewBox="0,0,300,150"
                        >
                            <g
                                fill="#4d4d4d"
                                fill-rule="nonzero"
                                stroke="none"
                                stroke-width="1"
                                stroke-linecap="butt"
                                stroke-linejoin="miter"
                                stroke-miterlimit="10"
                                stroke-dasharray=""
                                stroke-dashoffset="0"
                                font-family="none"
                                font-weight="none"
                                font-size="none"
                                text-anchor="none"
                            >
                                <g transform="scale(3.55556,3.55556)">
                                    <path d="M36,10c-1.139,0 -2.27708,0.38661 -3.20508,1.16211l-21.27734,17.7793c-1.465,1.224 -1.96564,3.32881 -1.05664,5.00781c0.75877,1.40048 2.14295,2.11605 3.53906,2.11719v13.93359c0,4.418 3.582,8 8,8h28c4.418,0 8,-3.582 8,-8v-13.92187c1.17234,-0.00057 2.33439,-0.51145 3.12695,-1.50391c1.377,-1.724 0.98597,-4.27055 -0.70703,-5.68555l-2.41992,-2.02148v-10.19922c0,-1.473 -1.19402,-2.66797 -2.66602,-2.66797h-2.66602c-1.473,0 -2.66797,1.19497 -2.66797,2.66797v3.51367l-10.79492,-9.01953c-0.928,-0.7755 -2.06608,-1.16211 -3.20508,-1.16211zM36,19.21289c0.22775,0 0.45513,0.07742 0.64063,0.23242l13.35938,11.16211v18.89258c0,0.276 -0.224,0.5 -0.5,0.5h-7.5v-12c0,-1.105 -0.895,-2 -2,-2h-8c-1.105,0 -2,0.895 -2,2v12h-7.5c-0.276,0 -0.5,-0.224 -0.5,-0.5v-18.89258l13.35938,-11.16211c0.1855,-0.155 0.41287,-0.23242 0.64063,-0.23242z"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </a>
                <p>Zaptic Library</p>
            </header>
            <body className="App-body">
                {accounts.length > 0 ? (
                    <div className="left-panel">
                        <div className="panel-header">Connections ({accounts.length})</div>
                        <a href="/add">
                            <div className="add-new">Add</div>
                        </a>
                        {accounts
                            .sort(function (a, b) {
                                if (a.customerName < b.customerName) {
                                    return -1
                                }
                                if (a.customerName > b.customerName) {
                                    return 1
                                }
                                return 0
                            })
                            .map((server) => (
                                <li
                                    className={server.customerId == selectedInstance?.customerId ? 'selected' : ''}
                                    onClick={() => setInstance(server)}
                                >
                                    {server.customerName}
                                    <a
                                        onClick={() => {
                                            fetch('/delete?customerId=' + server.customerId)
                                                .then((response) => response.text())
                                                .then((data) => {
                                                    setMessage(JSON.parse(data))
                                                    setInstance(null)
                                                })
                                        }}
                                    >
                                        <div className="delete">
                                            <svg
                                                fill="#98a8b7"
                                                height="200px"
                                                width="200px"
                                                version="1.1"
                                                id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 460.775 460.775"
                                            >
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                <g
                                                    id="SVGRepo_tracerCarrier"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    {' '}
                                                    <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path>{' '}
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            ))}
                    </div>
                ) : (
                    ''
                )}
                <div className="right-panel" style={accounts.length === 0 ? { width: '100%' } : {}}>
                    {!selectedInstance ? (
                        accounts.length > 0 ? (
                            <HomePage instances={accounts} />
                        ) : (
                            <div>
                                <div className="ViewFlows">
                                    <div className="Error">
                                        <h1>Zaptic Library</h1>
                                        <p>Add instances or integrations to build your library</p>
                                        <a href="/add">
                                            <div className="add-new">Add</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div key={selectedInstance.customerId}>
                            <ViewFlows account={selectedInstance} />
                        </div>
                    )}
                </div>
            </body>
        </div>
    )
}

export default App
