import { useEffect, useState } from 'react'
import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import './CopyWizard'
import './App.css'
import CopyWizard from './CopyWizard'
import type { accountType, procedureWorkflow, ProcedureSectionProps } from '../../types'

type Props = {
    account: accountType
}
function ViewFlows(props: Props) {
    const [message, setMessage] = useState({
        status: 'loading',
        flows: [] as SyncFlowWorkflow[],
    })
    const [selectedFlow, setFlow] = useState<SyncFlowWorkflow | null>(null)

    useEffect(() => {
        fetch('/getFlows?customerId=' + props.account.customerId)
            .then((response) => response.text())
            .then((data) => setMessage(JSON.parse(data)))
    }, [props.account.customerId])

    if (selectedFlow !== null) {
        return <CopyWizard selectedAccount={props.account} selectedWorkflow={selectedFlow} />
    }

    if (message.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )

    if (message.status === 'success') {
        var procedureWorkflows = {} as procedureWorkflow
        message.flows.forEach((flow) => {
            if (!flow.procedure) {
                if (!procedureWorkflows[0]) procedureWorkflows[0] = []
                return procedureWorkflows[0].push(flow)
            }

            if (!procedureWorkflows[flow.procedure.id]) procedureWorkflows[flow.procedure.id] = []

            return procedureWorkflows[flow.procedure.id].push(flow)
        })
        return (
            <div className="ViewFlows">
                <h2>Select flow to copy</h2>
                {Object.keys(procedureWorkflows).map(function (key, index) {
                    return (
                        <ProcedureSection
                            account={props.account}
                            setFlow={setFlow}
                            procedure={procedureWorkflows[parseInt(key)]}
                        />
                    )
                })}
            </div>
        )
    }

    return (
        <div className="ViewFlows">
            <div className="Error">
                <h1>⚠️ Error</h1>
                <p>Unfortunately an unknown error has occured when trying to load flows</p>
            </div>
        </div>
    )
}

function ProcedureSection(props: ProcedureSectionProps) {
    var procedureName
    procedureName = <h2>{!props.procedure[0].procedure ? 'Other' : props.procedure[0].procedure.name}</h2>
    return (
        <div className="ProcedureSection">
            {procedureName}
            <ul>
                {props.procedure.map((workflow) => {
                    return (
                        <li className={workflow.type}>
                            <p>
                                {workflow.name}
                                <sub>Version {workflow.numberOfVersions}</sub>
                            </p>
                            {workflow.procedure ? (
                                <a
                                    rel="noreferrer"
                                    href={
                                        'https://' +
                                        props.account.serverURL +
                                        '/procedures/' +
                                        workflow.procedure.id +
                                        '/tasks/workflows/' +
                                        workflow.id +
                                        '/edit'
                                    }
                                    target="_blank"
                                >
                                    <button>👁</button>
                                </a>
                            ) : (
                                ''
                            )}
                            <button
                                onClick={() => {
                                    props.setFlow(workflow)
                                }}
                            >
                                Copy
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ViewFlows
