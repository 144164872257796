import React, { useEffect, useState } from 'react'
import './App.css'
import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@mui/material'
import { GetAllEndpoint as UnitTypesOutput } from '../../../../server/http/unitTypes/getAll.no-deps'
import { GetAllProceduresEndpoint } from '../../../../server/http/procedures/getAll.no-deps'
import { findUnitQuestions, setUnitTypeId, updateFlow, replaceImages } from './logic'
import type {
    MappingMakerState,
    updates,
    unitQuestionArray,
    accountType,
    CopyWizardState,
    MapUnitTypeProps,
    CopyFlowsProps,
} from '../../types'
import { AddWorkflowOutput } from '../../..//../server/http/workflows/add.no-deps'

type Page = 'select-instances' | 'update-settings' | 'copy-flows'

function CopyWizard(props: CopyWizardState) {
    const [accounts, setAccounts] = useState<accountType[]>([])
    const [selectedInstances, setInstances] = useState<accountType[]>([])
    const [buttonDisabled, setButton] = useState<boolean>(true)
    const [page, setPage] = useState<Page>('select-instances')
    const [fromUnitTypes, setFromUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    useEffect(() => {
        if (!props.instances)
            fetch('/list')
                .then((response) => response.text())
                .then((data) => setAccounts(JSON.parse(data) as unknown as accountType[]))
        else setAccounts(props.instances)
        fetch('/getUnitTypes?customerId=' + props.selectedAccount.customerId)
            .then((response) => response.text())
            .then((data) => setFromUnitTypes(JSON.parse(data)))
            .then(() => {
                if (!props.instances)
                    fetch('/list')
                        .then((response) => response.text())
                        .then((data) => setAccounts(JSON.parse(data) as unknown as accountType[]))
                else setAccounts(props.instances)
            })
    }, [])

    const handleChange = (checked: any, account: accountType) => {
        if (checked.target.checked) selectedInstances.push(account)
        else selectedInstances.splice(selectedInstances.indexOf(account), 1)
        setInstances(selectedInstances)
        setButton(selectedInstances.length <= 0)
    }

    if (page === 'select-instances')
        return (
            <div className="ViewFlows">
                <h2>Copy to...</h2>
                <div className="ProcedureSection">
                    <FormGroup>
                        {accounts.map((act) => {
                            return act.customerId == props.selectedAccount.customerId ? (
                                <FormControlLabel disabled control={<Checkbox />} label={act.customerName} />
                            ) : (
                                <FormControlLabel
                                    onChange={(event) => {
                                        handleChange(event, act)
                                    }}
                                    control={<Checkbox />}
                                    label={act.customerName}
                                />
                            )
                        })}
                    </FormGroup>
                </div>
                <Button
                    onClick={() => {
                        setButton(true)
                        setPage('update-settings')
                    }}
                    variant="contained"
                    disabled={buttonDisabled}
                >
                    Next
                </Button>
            </div>
        )

    if (page === 'update-settings') {
        if (fromUnitTypes.status === 'loading')
            return (
                <div className="ViewFlows">
                    <div className="pulseLoader"></div>
                </div>
            )

        if (fromUnitTypes.status === 'fail')
            return <div className="ViewFlows">Failed to load unit types from {props.selectedAccount.customerName}</div>

        const newButtonStatus: boolean = selectedInstances.filter((si) => si.flow === undefined).length > 0

        if (newButtonStatus !== buttonDisabled) setButton(newButtonStatus)

        return (
            <div className="ViewFlows">
                <h2>Create Mappings</h2>
                {selectedInstances.map((instance) => {
                    return (
                        <div className="ProcedureSection">
                            <MappingMaker
                                returnFlow={(flow: SyncFlowWorkflow, returnInstance: accountType) => {
                                    setInstances([
                                        ...selectedInstances.map((si) => {
                                            if (si.customerId == returnInstance.customerId) {
                                                return {
                                                    flow: flow,
                                                    token: si.token,
                                                    relayStart: si.relayStart,
                                                    customerId: si.customerId,
                                                    customerName: si.customerName,
                                                    serverURL: si.serverURL,
                                                    customerSlug: si.customerSlug,
                                                }
                                            }
                                            return si
                                        }),
                                    ])
                                }}
                                fromUnitTypes={fromUnitTypes.unitTypes}
                                fromAccount={props.selectedAccount}
                                toAccount={instance}
                                selectedWorkflow={props.selectedWorkflow}
                            />
                        </div>
                    )
                })}
                <Button
                    onClick={() => {
                        setPage('copy-flows')
                    }}
                    disabled={buttonDisabled}
                    variant="contained"
                >
                    Next
                </Button>
            </div>
        )
    }

    if (page === 'copy-flows') {
        const newButtonStatus: boolean = selectedInstances.filter((si) => si.flow === undefined).length > 0

        if (newButtonStatus !== buttonDisabled) setButton(newButtonStatus)

        return (
            <div className="ViewFlows">
                <h2>Copying</h2>
                {selectedInstances.map((instance) => {
                    if (!instance.flow) return <div>Error: Missing Flow</div>
                    return (
                        <div className="ProcedureSection success">
                            <CopyFlows instance={instance} fromAccount={props.selectedAccount} />
                        </div>
                    )
                })}
                <Button
                    onClick={() => {
                        window.location.reload()
                    }}
                    disabled={buttonDisabled}
                    className="finish"
                    variant="contained"
                >
                    Finish
                </Button>
            </div>
        )
    }

    return (
        <div className="ViewFlows">
            <div className="ProcedureSection">
                <h2>404: Page Error</h2>
            </div>
        </div>
    )
}

export function MapUnitType(props: MapUnitTypeProps) {
    return (
        <div className="row">
            <div className="column">
                <p>
                    {props.fromUnit ? props.fromUnit.name : 'Missing Unit Type'}
                    <sub>{props.prompt}</sub>
                </p>
            </div>
            <div className="column">
                <FormControl fullWidth>
                    <InputLabel>Maps To:</InputLabel>
                    <Select
                        label="Unit Types"
                        value={props.preselectUnitType + ''}
                        onChange={(event) => {
                            props.handleChange(event)
                        }}
                    >
                        {props.toUnitTypes.map((unitType) => {
                            return <MenuItem value={unitType.id + ''}>{unitType.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

function MappingMaker(props: MappingMakerState) {
    const [unitTypes, setUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    const [procedures, setProcedures] = useState({
        status: 'loading',
        procedures: [] as GetAllProceduresEndpoint['Output']['procedures'],
    })
    const [newWorkflow, setNewWorkflow] = useState<updates>({
        name: props.selectedWorkflow.name,
        unitQuestions: props.selectedWorkflow.flow ? findUnitQuestions(props.selectedWorkflow.flow.steps, []) : [],
        procedureId: undefined,
    })

    useEffect(() => {
        fetch('/getUnitTypes?customerId=' + props.toAccount.customerId)
            .then((response) => response.text())
            .then((data) => setUnitTypes(JSON.parse(data)))
        fetch('/getProcedures?customerId=' + props.toAccount.customerId)
            .then((response) => response.text())
            .then((data) => setProcedures(JSON.parse(data)))
    }, [props.toAccount.customerId])

    if (!props.selectedWorkflow.flow)
        return <div>Legacy 'workflow' are not supported by this tool, please select a Flow</div>

    if (unitTypes.status === 'loading' || procedures.status === 'loading') return <div className="pulseLoader"></div>

    if (unitTypes.status === 'fail' || props.fromUnitTypes === undefined || procedures.status === 'fail')
        return <div>Failed to load</div>

    const subjectUnitType = props.fromUnitTypes.find((unitType) => {
        return (
            props.selectedWorkflow.flow &&
            props.selectedWorkflow.flow.subjectType &&
            unitType.id === props.selectedWorkflow.flow.subjectType.unitType
        )
    })

    // Check if all fields are filled
    const isComplete =
        newWorkflow.name &&
        newWorkflow.name.length > 0 &&
        newWorkflow.procedureId &&
        newWorkflow.unitQuestions.filter((uq) => !uq.newUnitTypeId).length === 0

    if (isComplete && newWorkflow.procedureId) {
        var workflow = JSON.parse(JSON.stringify(props.selectedWorkflow))
        workflow.name = newWorkflow.name
        workflow.procedure = {
            id: newWorkflow.procedureId,
            name: '',
            description: null,
        }
        workflow.flow = JSON.parse(JSON.stringify(props.selectedWorkflow.flow))
        if (!workflow.flow) return <div>Error</div>
        workflow.flow.steps = updateFlow(workflow.flow.steps, newWorkflow)

        const subjectUnit = newWorkflow.unitQuestions.find((uq) => uq.questionType === 'subject')

        if (subjectUnit && subjectUnit.newUnitTypeId) {
            workflow.flow.subjectType = {
                type: 'SubjectTypeUnit',
                unitType: subjectUnit.newUnitTypeId,
            }
        }
        if (JSON.stringify(props.toAccount.flow) !== JSON.stringify(workflow))
            props.returnFlow(workflow, props.toAccount)
    }

    function getQuestionLabel(question: unitQuestionArray) {
        switch (question.questionType) {
            case 'loop':
                return 'Loop Element: ' + question.questionLabel
            case 'unit-select':
                return 'Unit Select Element: ' + question.questionLabel
            case 'subject':
                return question.questionLabel
            default:
                return 'Unit Filter For: ' + question.questionLabel
        }
    }

    if (
        props.selectedWorkflow.flow &&
        props.selectedWorkflow.flow.subjectType &&
        subjectUnitType &&
        !newWorkflow.unitQuestions.find((uq) => uq.questionType === 'subject')
    )
        newWorkflow.unitQuestions.unshift({
            questionId: props.selectedWorkflow.flow.id,
            questionType: 'subject',
            unitTypeId: props.selectedWorkflow.flow.subjectType.unitType,
            questionLabel: 'Flow Subject',
        })
    return (
        <div>
            <div className="row">
                <div className="column">
                    <h2>
                        Copy from <b>{props.fromAccount.customerName}</b> to...
                    </h2>
                </div>
                <div className="column">
                    <h2>
                        <b>{props.toAccount.customerName}</b>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <p>
                        Name Flow
                        <sub>Name must be unique to the procedure</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <TextField
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    name: event.target.value,
                                    procedureId: newWorkflow.procedureId,
                                })
                            }}
                            value={newWorkflow.name}
                            variant="outlined"
                        />
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Select destination procedure
                        <sub>You must have procedure admin or be a collaborator</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <InputLabel>Target Procedure</InputLabel>
                        <Select
                            label="Target Procedure"
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    name: newWorkflow.name,
                                    procedureId: parseInt(event.target.value + ''),
                                })
                            }}
                        >
                            {procedures.procedures.map((procedure) => {
                                return <MenuItem value={procedure.id}>{procedure.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>

            {
                // Look for all unit based questions and get mapping
                newWorkflow.unitQuestions.map((unitQuestion) => {
                    return (
                        <MapUnitType
                            fromUnit={
                                props.fromUnitTypes
                                    ? props.fromUnitTypes.find((unitType) => {
                                        return unitType.id === unitQuestion.unitTypeId
                                    })
                                    : undefined
                            }
                            toUnitTypes={unitTypes.unitTypes}
                            prompt={getQuestionLabel(unitQuestion)}
                            handleChange={(event: any) => {
                                setNewWorkflow({
                                    unitQuestions: setUnitTypeId(event, unitQuestion, newWorkflow),
                                    name: newWorkflow.name,
                                    procedureId: newWorkflow.procedureId,
                                })
                            }}
                            preselectUnitType={unitQuestion.newUnitTypeId}
                        />
                    )
                })
            }
            <div className="row">
                {isComplete ? <sub className="green">COMPLETE</sub> : <sub className="red">INCOMPLETE</sub>}
            </div>
        </div>
    )
}

function CopyFlows(props: CopyFlowsProps) {
    const [newWorkflow, setNewWorkflow] = useState({
        status: 'loading',
        response: {} as AddWorkflowOutput,
    })

    useEffect(() => {
        replaceImages(props.instance, props.instance.flow).then((newFlow: SyncFlowWorkflow) => {
            fetch(
                '/copyFlow?customerId=' +
                props.instance.customerId +
                '&sourceCustomerId=' +
                props.fromAccount.customerId,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newFlow),
                }
            )
                .then((response) => response.text())
                .then((data) => setNewWorkflow(JSON.parse(data)))
        })
    }, [props.instance, props.fromAccount])

    if (newWorkflow.status === 'loading') return <div className="pulseLoader"></div>
    if (
        newWorkflow.status === 'fail' ||
        !newWorkflow.response ||
        !newWorkflow.response.workflow ||
        !props.instance.flow
    )
        return <div>An error has occured</div>
    return (
        <li>
            <p>
                ✅ {props.instance.flow.name}
                <sub>
                    Flow copied successfully! Now you can add schedules, statuses, filters and other instance level
                    features by pressing the preview icon
                </sub>
            </p>
            {props.instance.flow.procedure ? (
                <a
                    rel="noreferrer"
                    href={
                        'https://' +
                        props.instance.serverURL +
                        '/procedures/' +
                        props.instance.flow.procedure.id +
                        '/tasks/workflows/' +
                        newWorkflow.response.workflow.id +
                        '/edit'
                    }
                    target="_blank"
                >
                    <button>👁</button>
                </a>
            ) : (
                ''
            )}
        </li>
    )
}

export default CopyWizard
