import {
    HomePageProps,
    DuplicateFlows,
    DuplicateFlow,
    ViewCopiesProps,
} from '../../types'
import { useEffect, useState } from 'react'
import { compareFlows } from './logic'
import CopyWizard from './CopyWizard'
import {
    FormControlLabel,
    Checkbox,
    Button,
} from '@mui/material'
import UpdateWizard from './updateWizard'
function HomePage(props: HomePageProps) {
    const [duplicateFlows, setDuplicateFlows] = useState<DuplicateFlows | null>(null)
    const [selectedFlow, setSelectedFlow] = useState<DuplicateFlow[] | null>(null)
    const [currentPage, setCurrentPage] = useState<'home' | 'update'>('home')

    useEffect(() => {
        if (!selectedFlow)
            fetch('/getFlowMatches')
                .then((response) => response.text())
                .then(async (data) => {
                    setDuplicateFlows(JSON.parse(data))
                    setSelectedFlow(selectedFlow)
                })
    }, [])
    if (!duplicateFlows)
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )

    if (selectedFlow) {
        var hostAccount = props.instances.find((instance) => instance.customerId == selectedFlow[0].customerId)
        return (
            <div>
                <h2>Live Versions</h2>
                <ViewCopies
                    currentPage={currentPage}
                    setPage={setCurrentPage}
                    instances={props.instances}
                    duplicateFlows={selectedFlow}
                />
                {hostAccount && currentPage !== 'update' ? (
                    <CopyWizard
                        instances={props.instances.filter(
                            (instance) => selectedFlow.filter((sf) => sf.customerId == instance.customerId).length == 0
                        )}
                        selectedWorkflow={selectedFlow[0].flow}
                        selectedAccount={hostAccount}
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }
    var flows = Object.keys(duplicateFlows).map(function (key, index) {
        if (duplicateFlows[key][0].image)
            var image = (
                <img
                    src={duplicateFlows[key][0].image}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = './question-mark-grey.svg'
                        currentTarget.className = 'icon'
                    }}
                ></img>
            )
        else var image = <img src="./question-mark-grey.svg" className="icon" />
        return (
            <div
                className="tile"
                onClick={() => {
                    setSelectedFlow(duplicateFlows[key])
                }}
            >
                <div className="coverimage">{image}</div>
                <div className="info">
                    <div className="info-row title">{duplicateFlows[key][0].flow.name}</div>
                    <div className="info-row">
                        <div className="count">
                            <div>{duplicateFlows[key].length}</div>
                        </div>
                        instances
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="HomePage">
            {flows.length > 0 ? (
                flows
            ) : (
                <div className="ViewFlows">
                    <div className="Error">
                        <h1>Copy Flows</h1>
                        <p>Select an instance on the left and copy flows to build a library</p>
                    </div>
                </div>
            )}
        </div>
    )
}
function ViewCopies(props: ViewCopiesProps) {
    const [selectedFlow, setSelectedFlow] = useState<DuplicateFlow | null>(null)
    const [updateFlows, setUpdateFlows] = useState<DuplicateFlow[]>([])

    if (props.currentPage === 'update' && updateFlows.length > 0 && selectedFlow)
        return (
            <UpdateWizard
                selectedFlow={selectedFlow}
                selectedFlows={updateFlows}
                instances={props.instances}
                duplicateFlows={props.duplicateFlows}
            />
        )

    return (
        <div className="ProcedureSection">
            {props.duplicateFlows.map((flow) => {
                var instance = props.instances.find((account) => account.customerId == flow.customerId)
                if (!instance) return <li>Error</li>

                var differences
                if (selectedFlow && selectedFlow !== flow && selectedFlow.flow?.flow?.steps && flow.flow?.flow?.steps) {
                    differences = compareFlows(selectedFlow.flow?.flow?.steps, flow.flow?.flow?.steps)
                }
                return (
                    <li>
                        <p>
                            <b>{instance.customerName}</b> - {flow.flow.name}
                            {differences ? (
                                differences.missingSteps.length === 0 && differences.newSteps.length === 0 ? (
                                    <sub>
                                        <div className="added">✅ No Changes</div>
                                    </sub>
                                ) : (
                                    <sub>
                                        <div className="missing">{differences.missingSteps.length} items removed</div>
                                        <div className="added">{differences.newSteps.length} items added</div>
                                    </sub>
                                )
                            ) : (
                                <sub>Version {flow.flow.numberOfVersions}</sub>
                            )}
                        </p>
                        {flow.flow.procedure ? (
                            <a
                                rel="noreferrer"
                                href={
                                    'https://' +
                                    instance.serverURL +
                                    '/procedures/' +
                                    flow.flow.procedure.id +
                                    '/tasks/workflows/' +
                                    flow.flow.id +
                                    '/edit'
                                }
                                target="_blank"
                            >
                                <button>👁</button>
                            </a>
                        ) : (
                            ''
                        )}
                        {selectedFlow ? (
                            flow.customerId === selectedFlow.customerId && flow.flow.id === selectedFlow.flow.id ? (
                                <Button
                                    className="selected"
                                    onClick={() => {
                                        setSelectedFlow(null)
                                    }}
                                >
                                    Compare
                                </Button>
                            ) : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) => {
                                                if (event.target.checked && updateFlows.indexOf(flow) === -1)
                                                    updateFlows.push(flow)
                                                else if (!event.target.checked)
                                                    updateFlows.splice(updateFlows.indexOf(flow), 1)
                                                setUpdateFlows([...updateFlows])
                                            }}
                                        />
                                    }
                                    labelPlacement="start"
                                    label="Update"
                                />
                            )
                        ) : (
                            <Button
                                onClick={() => {
                                    setSelectedFlow(flow)
                                }}
                            >
                                Compare
                            </Button>
                        )}
                    </li>
                )
            })}
            {selectedFlow ? (
                <Button
                    disabled={updateFlows.length === 0}
                    variant="contained"
                    onClick={() => {
                        props.setPage('update')
                    }}
                >
                    Next
                </Button>
            ) : (
                ''
            )}
        </div>
    )
}

export default HomePage
